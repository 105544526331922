<template>
  <el-dialog
    title="温馨提示"
    width="468px"
    :visible="visible"
    :close-on-click-modal="false"
    custom-class="certification-dialog"
    :append-to-body="true"
    @close="handleCloseDialog"
  >
    <div class="dialog-content">
      <div class="content">
        <template v-if="!pay">
          <p>亲爱的创业者，您今天联系投资人的次数已用尽，请明天再试。</p>
          <p>拥有更多联系次数请获取极速融资服务。</p>
        </template>
        <template v-else>
          <p>亲爱的创业者，您今天联系投资人的次数已用尽，请明天再试~</p>
        </template>
      </div>
    </div>
    <div slot="footer">
      <template v-if="!pay">
        <el-button
          size="mini"
          @click="handleCloseDialog"
        >
          下次再说
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="handleClick"
        >
          点击了解极速融资
        </el-button>
      </template>
      <template v-else>
        <el-button
          size="mini"
          @click="handleCloseDialog"
        >
          我知道了
        </el-button>
      </template>
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    pay: { // 是否付费用户
      type: Boolean,
      default: false,
    },
  },
  computed: {
  },
  methods: {
    handleClick() {
      this.handleCloseDialog();
      const url = `${this.$config.rongH5Url}/jingdataService/finance/public?channel=mzhaofuwu&id=1`;
      window.open(url, '_blank');
    },
    handleCloseDialog() {
      this.$emit('update:visible', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  .content {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 28px;
    margin: 20px 0;
  }
</style>
